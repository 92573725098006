.cryptoc-currencies{margin-bottom: 164px;}
/* .cryptoc-currencies .main-cryptoc-currencies {padding: 0 223px;} */
.cryptoc-currencies .main-cryptoc-currencies {max-width: 1214px;margin: 0 auto;}
.cryptoc-currencies .heading-currencies{margin-bottom: 92px;}
.cryptoc-currencies .heading-currencies h4{font-size:62px;line-height:70px;font-weight:400;font-family:'Outfit';max-width:880px;margin:0 auto 42px;}
.cryptoc-currencies .heading-currencies span{font-size:24px;line-height:30px;font-weight:400;color: #BFBFD6;font-family:'Outfit';}
.cryptoc-currencies .img-box{background-color:#041134;border: 1px solid #1B2D5E;padding:24px 15px;border-radius: 4px;margin-bottom: 15px;width: 50%;}
.cryptoc-currencies .img-box .circle-img{width: 17px;margin-right: 18px;} 
.cryptoc-currencies .img-box p{font-size: 24px;line-height: 30px;font-weight: 400;color: #BFBFD6;font-family:'Outfit'; margin-bottom: 0;}
.cryptoc-currencies .gap-right{margin-right: 24px;}
.cryptoc-currencies .gap-left{margin-left: 24px;}
.cryptoc-currencies .cryptoc-box{margin-bottom: 126px;}

@media (max-width:1780px) {
    .cryptoc-currencies {margin-bottom: 150px;}
    .cryptoc-currencies .img-box p {font-size: 22px;line-height: 28px;}
    /* .cryptoc-currencies .main-cryptoc-currencies {padding: 0 85px;} */
    .cryptoc-currencies .heading-currencies h4 {font-size: 60px;line-height: 68px;max-width: 750px;margin: 0 auto 38px;}
    .cryptoc-currencies .heading-currencies {margin-bottom: 85px;}
    .cryptoc-currencies .cryptoc-box { margin-bottom: 115px;}
}

@media (max-width:1580px) {
    .cryptoc-currencies {margin-bottom: 135px;}
    .cryptoc-currencies .heading-currencies h4 {font-size: 58px;line-height: 63px;max-width: 700px;margin: 0 auto 35px;}
    .cryptoc-currencies .heading-currencies span { font-size: 23px; line-height: 29px;}
    .cryptoc-currencies .heading-currencies {margin-bottom: 80px;}
    .cryptoc-currencies .img-box { padding: 22px 15px;}
    .cryptoc-currencies .gap-right {margin-right: 20px;}
    .cryptoc-currencies .cryptoc-box {margin-bottom: 110px;}
}

@media (max-width:1399px) {
    .cryptoc-currencies { margin-bottom: 120px;}
    .cryptoc-currencies .heading-currencies h4 {font-size: 55px;line-height: 60px;max-width: 660px;margin: 0 auto 33px;}
    .cryptoc-currencies .heading-currencies span { font-size: 22px; line-height: 28px;}
    .cryptoc-currencies .heading-currencies {margin-bottom: 75px; }
    /* .cryptoc-currencies .main-cryptoc-currencies { padding: 0 74px; } */
    .cryptoc-currencies .img-box .circle-img {width: 16px;margin-right: 15px;}
    .cryptoc-currencies .img-box {padding: 20px 12px;}
    .cryptoc-currencies .img-box p {font-size: 19px;line-height: 25px;}
    .cryptoc-currencies .cryptoc-box {margin-bottom: 100px;}
    .cryptoc-currencies .gap-right { margin-right: 18px;}
    .cryptoc-currencies .gap-left { margin-left: 18px;}
}

@media (max-width:1199px) {
    .cryptoc-currencies {margin-bottom: 110px;}
    .cryptoc-currencies .heading-currencies {margin-bottom: 70px;}
    .cryptoc-currencies .heading-currencies h4 {font-size: 50px;line-height: 56px;max-width: 600px;margin: 0px auto 30px;}
    .cryptoc-currencies .heading-currencies span { font-size: 20px; line-height: 26px;}
    /* .cryptoc-currencies .main-cryptoc-currencies {padding: 0px 40px;} */
    .cryptoc-currencies .img-box p {font-size: 16px;line-height: 22px;}
}

@media (max-width:991px) {
    .cryptoc-currencies { margin-bottom: 90px;}
    .cryptoc-currencies .cryptoc-box {margin-bottom: 80px;}
    .cryptoc-currencies .heading-currencies h4 {font-size: 45px;line-height: 50px;max-width: 500px;margin: 0px auto 25px;}
    .cryptoc-currencies .heading-currencies span {font-size: 18px;line-height: 24px;line-height: 23px; }
    .cryptoc-currencies .heading-currencies {margin-bottom: 60px;}
    /* .cryptoc-currencies .main-cryptoc-currencies { padding: 0px 0px;} */
    .cryptoc-currencies .img-box p {font-size: 13px;line-height: 20px;}
    .cryptoc-currencies .gap-right { margin-right: 5px;}
    .cryptoc-currencies .img-box { padding: 13px 10px; }
    .cryptoc-currencies .gap-left { margin-left: 5px; }
}

@media (max-width:767px) {
    .cryptoc-currencies { margin-bottom: 80px;
         /* padding: 90px 0 0 0; */
        }
    .cryptoc-currencies .heading-currencies h4 {font-size: 35px;line-height: 42px;}
    .cryptoc-currencies .heading-currencies span { font-size: 16px; line-height: 20px;}
    .cryptoc-currencies .heading-currencies { margin-bottom: 50px;}
    .cryptoc-currencies .img-box { width: 100%;}
    .cryptoc-currencies .gap-right {margin-right: 0;}
    .cryptoc-currencies .gap-left {margin-left: 0;}
}

@media (max-width:575px) {
    .cryptoc-currencies .cryptoc-box { margin-bottom: 60px;}
    .cryptoc-currencies .heading-currencies h4 {font-size: 30px;line-height: 35px;}
    .cryptoc-currencies .heading-currencies span {font-size: 15px;line-height: 20px; }
    .cryptoc-currencies .img-box p {font-size: 12px;line-height: 18px; }
    .cryptoc-currencies .img-box { padding: 10px 10px; }
    .cryptoc-currencies { margin-bottom: 70px;
         /* padding: 80px 0 0 0; */
        }
}

@media (max-width:480px) {
    .cryptoc-currencies { margin-bottom: 50px;
         /* padding: 70px 0 0 0; */
        }
    .cryptoc-currencies .heading-currencies h4 { font-size: 20px; line-height: 25px;}
    .cryptoc-currencies .heading-currencies span { font-size: 14px; line-height: 18px;}
    .cryptoc-currencies .cryptoc-box { margin-bottom: 50px;}
}

@media (max-width:320px) {
    .cryptoc-currencies .heading-currencies h4 { font-size: 17px; line-height: 23px;}
}

