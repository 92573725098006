.startaccount { padding-bottom: 113px;}
.startaccount .start-account-modal button{background-color:#37404C;border-color:#37404C;font-size:16px;line-height:22px;font-weight:500;transition: all ease-in 0.3s;padding: 15px 30px;}
.startaccount .start-account-modal button:hover{background-color:#0095C8;border-color:#0095C8;}
.modal-header{margin-bottom: 30px;}
.modal-header .modal-title.h4{color: #fff;font-size: 24px;line-height: 30px;font-weight: 500;}
.modal-body .main-button{margin-bottom: 30px;display: flex;justify-content: center;align-items: center;}
.modal-body .main-button button{background-color:#37404C;border-color: #37404C;font-size:18px;font-weight:600;line-height:25px; padding: 13px 25px;transition: all ease-in-out 0.3s;}
.modal-body .main-button span{background-color:#37404C;border-color: #37404C;font-size:18px;font-weight:600;line-height:25px; padding: 13px 25px;transition: all ease-in-out 0.3s;border-radius: 5px;}
.modal-body .main-button button:hover{background-color:#0095C8;border-color:#0095C8;}
.btn-close{background: url('../../assets/images/close.svg');width: 1px;background-repeat: no-repeat;}
.modal-body input.start-input{border: 1px solid #37404C;background: transparent;padding: 13px 20px;color: #fff;}
.modal-body input.start-input:focus{box-shadow: none;}
.qrpcode-span.text-center.pb-3 {background: #fff;width: 210px;padding: 15px;margin: 0 auto 30px;border-radius: 5px;}

@media (max-width:1399px){
    .startaccount .start-account-modal button {font-size: 15px;line-height: 20px;padding: 13px 27px;}
    .modal-header .modal-title.h4 {font-size: 23px;line-height: 29px;}
    .modal-body .main-button button {font-size: 17px;line-height: 23px;padding: 9px 22px;}

}
@media (max-width:1199px){
    .modal-header .modal-title.h4 {font-size: 22px;line-height: 28px;}
    .modal-body .main-button button {font-size: 16px;line-height: 22px;padding: 9px 22px;}
    .modal-body .main-button { margin-bottom: 25px;}
}

@media (max-width:991px){
    .startaccount .start-account-modal button {font-size: 14px;line-height: 20px;padding: 7px 19px;}
    .modal-header .modal-title.h4 {font-size: 20px;line-height: 25px;}
    .modal-body .main-button button {font-size: 15px;line-height: 19px;padding: 8px 22px;}
    .modal-body .main-button { margin-bottom: 20px;}
}

@media (max-width:767px){
    .startaccount .start-account-modal button {line-height: 19px;}
    .modal-header .modal-title.h4 {font-size: 18px;line-height: 23px;}
}

@media (max-width:575px){
    .startaccount .start-account-modal button {font-size: 13px;padding: 5px 15px;}
    .modal-header .modal-title.h4 {font-size: 17px;line-height: 22px;}
    .modal-body .main-button button {font-size: 14px;line-height: 18px;padding: 7px 12px;}
}

@media (max-width:320px){
    .modal-header .modal-title.h4 {font-size: 15px;line-height: 20px;}
}


   

