.simple-stap {background-image: url('../../assets/images/stap-bg2.png');background-size: cover;position: relative;}
.main-heading-paragraphes {margin-bottom: 350px;}
.simple-stap .crypto-card-max-width {max-width: 1290px;margin: 0 auto;}
.simple-stap .heading-paragraphes{margin-bottom: 161px;}
.simple-stap .heading-paragraphes h4{font-size: 62px;line-height: 70px;font-weight: 400;font-family: 'Outfit';margin-bottom: 41px;}
.simple-stap .heading-paragraphes p{font-size: 24px;line-height:30px;font-weight:400;color: #BFBFD6;}
.simple-stap .stap-img{ width: 968px;margin: 0 auto 144px;}
.simple-stap .stap-button{margin-bottom: 132px;}

.simple-stap .main-wallet-app {background-color: #041134;padding: 53px 20px;}
.simple-stap  .crypto-card-max-width {max-width: 1290px;margin: 0 auto;}
.simple-stap  .main-wallet-app h5{font-size:34px;line-height:40px;font-weight:400;margin-bottom: 20px;}
.simple-stap  .main-wallet-app p{font-size:24px;line-height:30px;font-weight:400;color: #BFBFD6;}
.simple-stap .main-wallet-app {background-color: #041134;padding: 53px 20px;position: absolute;width: 100%;right: 0;bottom: 0;}

@media (max-width:1780px) {
    .simple-stap .heading-paragraphes h4 {font-size: 60px;line-height: 68px;margin-bottom: 40px;}
    .simple-stap .heading-paragraphes p { font-size: 23px;line-height: 28px;}
    .simple-stap .crypto-card-max-width { max-width: 1200px;}
    .simple-stap .stap-img {width: 930px;margin: 0 auto 130px; }
    .simple-stap .stap-button {margin-bottom: 120px;}
    .simple-stap .heading-paragraphes {margin-bottom: 150px;}
    .simple-stap .main-wallet-app h5 {font-size: 32px;line-height: 38px; margin-bottom: 18px;}
    .simple-stap .main-wallet-app p { font-size: 23px; line-height: 28px;}
    .simple-stap .main-wallet-app {padding: 45px 19px;}
}

@media (max-width:1580px) {
    .simple-stap .heading-paragraphes h4 {font-size: 59px;line-height: 66px;margin-bottom: 38px;}
    .simple-stap .heading-paragraphes p {font-size: 22px;line-height: 26px;line-height: 24px;}
    .simple-stap .stap-img {margin: 0 auto 115px; }
    .simple-stap .heading-paragraphes {margin-bottom: 130px;}
   .simple-stap .main-wallet-app h5 { font-size: 30px; line-height: 35px; margin-bottom: 15px; }
   .simple-stap .main-wallet-app p {font-size: 22px;line-height: 26px;}
   .simple-stap .main-wallet-app {padding: 42px 18px;}
}


@media (max-width:1399px) {
    .simple-stap .heading-paragraphes h4 {font-size: 55px;line-height: 62px;margin-bottom: 35px;}
    .simple-stap .heading-paragraphes p {font-size: 21px;line-height: 25px;}
    .simple-stap .stap-img {margin: 0 auto 100px;}
    .simple-stap .heading-paragraphes {margin-bottom: 110px;}
    .simple-stap .stap-button { margin-bottom: 100px;}
    .simple-stap .main-wallet-app h5 {font-size: 29px;line-height: 34px;}
    .simple-stap .main-wallet-app p {font-size: 21px;line-height: 25px;}
    .simple-stap .main-heading-paragraphes {margin-bottom: 295px;}
    .simple-stap .main-wallet-app {padding: 40px 17px;}
}

@media (max-width:1199px) {
    .simple-stap .crypto-card-max-width { max-width: 910PX;}
    .simple-stap .heading-paragraphes h4 {font-size: 50px;line-height: 60px;margin-bottom: 30px;}
    .simple-stap .heading-paragraphes p { font-size: 18px; line-height: 23px;}
    .simple-stap .stap-img {margin: 0px auto 90px;width: 100%; }
    .simple-stap .heading-paragraphe {margin-bottom: 100px;}
    .simple-stap .stap-button { margin-bottom: 90px;}
    .simple-stap {padding: 30px 20px;}
    .simple-stap .main-wallet-app h5 {font-size: 28px;line-height: 33px;}
    .simple-stap .main-wallet-app p { font-size: 20px; line-height: 25px;}
    .simple-stap .main-heading-paragraphes { margin-bottom: 220px; }
    .simple-stap .main-wallet-app {padding: 30px 15px;}
}

@media (max-width:991px) {
    .simple-stap .heading-paragraphe h4 { font-size: 38px; line-height: 45px; margin-bottom: 25px;}
    .simple-stap .heading-paragraphe p {font-size: 14px;line-height: 20px; }
    .simple-stap .heading-paragraphe {margin-bottom: 80px;}
    .simple-stap .stap-img {width: 100%;  margin: 0px auto 80px; }
    .simple-stap .stap-button { margin-bottom: 80px;}
    .simple-stap { padding: 24px 15px;}
    .simple-stap .main-wallet-app h5 {font-size: 20px;line-height: 25px;}
    .simple-stap .main-wallet-app p {font-size: 14px;line-height: 19px;}
    .simple-stap .main-heading-paragraphes { margin-bottom: 170px; }
    .simple-stap .main-wallet-app {padding: 25px 15px;}
}

@media (max-width:767px) {
    .simple-stap .heading-paragraphes h4 {font-size: 30px;line-height: 38px;margin-bottom: 20px;}
    .simple-stap .heading-paragraphes p {font-size: 13px;line-height: 19px;}
    .simple-stap .heading-paragraphes { margin-bottom: 60px; }
    .simple-stap .stap-img {margin: 0px auto 70px; }
    .simple-stap .stap-button { margin-bottom: 70px;}
    .simple-stap .main-wallet-app h5 {font-size: 18px;line-height: 23px;}
    .simple-stap .main-wallet-app p { font-size: 13px; line-height: 18px;}
    .simple-stap .main-heading-paragraphes {margin-bottom: 150px; }
    .simple-stap .main-wallet-app {padding: 20px 15px;}
}

@media (max-width:575px) {
    .simple-stap .heading-paragraphes h4 {font-size: 25px;line-height: 30px;margin-bottom: 20px;}
    .simple-stap .heading-paragraphes p { font-size: 12px; line-height: 18px;}
    .simple-stap .heading-paragraphes { margin-bottom: 50px; }
    .simple-stap .stap-img {  margin: 0px auto 60px;}
    .simple-stap .main-wallet-app h5 { font-size: 17px; line-height: 22px;}
    .simple-stap .main-wallet-app p {font-size: 12px; }
    .simple-stap .main-heading-paragraphes { margin-bottom: 140px;}
}

@media (max-width:480px) {
    .simple-stap .stap-img { margin: 0px auto 40px; }
    .simple-stap .heading-paragraphes h4 {  font-size: 20px;  line-height: 25px;}
    .simple-stap .heading-paragraphes { margin-bottom: 40px; }
    .simple-stap .stap-button {margin-bottom: 50px;}
    .simple-stap .main-wallet-app h5 {font-size: 16px;line-height: 20px;}
}

@media (max-width:320px) {
    .simple-stap .heading-paragraphes h4 {font-size: 17px;line-height: 23px;}
    .simple-stap .main-wallet-app h5 { font-size: 15px; line-height: 19px;}
    .simple-stap {padding: 15px 8px;}
}
