.private-secure {margin-bottom: 213px;}
.private-secure .private-secure-img{ width: 667px; margin: 0 auto; }

@media (max-width:1780px) {
    .private-secure {margin-bottom: 200px;}
    .private-secure .private-secure-img { width: 600px;}
}


@media (max-width:1580px) {
    .private-secure { margin-bottom: 170px;}
    .private-secure .private-secure-img { width: 100%;}
}

@media (max-width:1399px) {
    .private-secure { margin-bottom: 150px;}
}

@media (max-width:1199px) {
    .private-secure { margin-bottom: 120px;}
}

@media (max-width:991px) {
    .private-secure {margin-bottom: 90px;}
}

@media (max-width:767px) {
    .private-secure {margin-bottom: 80px;}
}


@media (max-width:575px) {
    .private-secure {margin-bottom: 60px; }
}

@media (max-width:480px) {
    .private-secure {margin-bottom: 50px;}

}

@media (max-width:320px) {
}
