.footer{ background-color: #29313C; padding: 85px 0px 0px 0px; margin-top: -3px;}
.footer strong.footer-logo img { width: 100px;}
.footer .top-footer{border-bottom: 1px solid #48515F;}
.footer strong.footer-logo{ margin-bottom: 42px;}
.footer ul.footer-unorder-list{max-width: 200px;}
.footer h3.about-us{ font-size: 24px; line-height: 30px; font-weight: 700;color: #fff;margin-bottom: 16px;}
.footer li.list-footer{ margin-bottom: 12px;margin-right: 41px;}
.footer li.list-footer a{ color: #fff; font-size: 18px; line-height: 22px; font-weight: 400;
transition: all ease-in-out 0.3s;}
.footer li.list-footer a:hover{ color:#0095C8 ;}
.footer h3.investors{color: #0095C8;}
.footer ul li a .icon{  height: 15px;  width: 15px; color: #29313C;}
.footer .icon-circle {border: 1px solid #fff;height: 25px;width: 25px;border-radius: 50%;display: flex;
justify-content: center; align-items: center; background-color: #fff;}
.footer .footer-col { margin-bottom: 76px;}
.footer .bottom-footer{ padding: 37px 0px 46px;}
.footer strong{ color: #fff; font-size:18px;font-weight:400 ; line-height:22px ; margin-bottom: 8px;}
.footer span{ color: #fff; font-size:14px ;font-weight:300 ; line-height:18px ;}

/* @media qurry */

@media (max-width:1780px) {
    .footer h3.about-us { font-size: 23px; line-height: 28px; margin-bottom: 15px;}
    .footer li.list-footer a {font-size: 17px;line-height: 21px;font-weight: 400;}
}

@media (max-width:1580px){
    .footer h3.about-us { font-size: 20px; line-height: 26px; margin-bottom: 14px;}
    .footer li.list-footer a {font-size: 16px;line-height: 20px;font-weight: 400;}
}

@media (max-width:1399px){
    .footer strong.footer-logo.d-inline-block img { width: 60px;}
    .footer {  padding: 60px 0px 0px 0px; }
    .footer strong.footer-logo { margin-bottom: 35px;}
    .footer h3.about-us { font-size: 16px; line-height: 22px;}
    .footer li.list-footer a {font-size: 14px;line-height: 18px;}
    .footer ul.footer-unorder-list { max-width: 135px;}
    .footer li.list-footer {margin-bottom: 12px;margin-right: 20px;}
    .footer strong { font-size: 17px; line-height: 21px;}
    .footer span { font-size: 13px; line-height: 17px;}
    .footer .footer-col { margin-bottom: 60px;}
}

@media (max-width:1199px){
    .footer {padding: 50px 0px 0px 0px;}
    .footer strong.footer-logo {margin-bottom: 25px;}
    .footer h3.about-us { font-size: 15px; line-height: 20px;}
    .footer .footer-col {margin-bottom: 50px;}
    .footer li.list-footer a {font-size: 13px;line-height: 17px;}
    .footer strong { font-size: 16px; line-height: 20px;}
    .footer .bottom-footer {padding: 30px 0px 35px;}
    .footer span {font-size: 12px;line-height: 16px;}
    .footer ul li a .icon { height: 12px; width: 12px;}
    .footer .icon-circle { height: 22px; width: 22px;}
    .footer li.list-footer { margin-right: 15px;}
}

@media (max-width:991px){
    .footer { padding: 40px 0px 0px 0px;}
    .footer strong.footer-logo { margin-bottom: 20px;}
    .footer h3.about-us { font-size: 14px; line-height: 19px;}
    .footer li.list-footer a {font-size: 12px;line-height: 16px;}
    .footer li.list-footer { margin-right: 15px;  margin-bottom: 5px;}
    .footer ul li a .icon { height: 10px; width: 10px;}
    .footer .icon-circle { height: 18px; width: 18px;}
    .footer .footer-col {margin-bottom: 40px;}
    .footer .bottom-footer {padding: 25px 0px 25px;}
    .footer strong {font-size: 14px;line-height: 18px;margin-bottom: 5px;}
}

@media (max-width:767px){
    .footer .footer-col { margin-bottom: 35px;}
    .footer ul.footer-unorder-list {  max-width: 170px;}
}

@media (max-width:575px){
    .footer strong.footer-logo.d-inline-block img {width: 50px;}
    .footer { padding: 30px 0px 0px 0px;}
    .footer strong.footer-logo.d-inline-block { display: flex !important; justify-content: center; align-items: center;}
}