.headerunautha {padding:12px 0px;position: absolute;width:100%;z-index: 2;}
.headerunautha .header-logo a.navbar-brand img{width: 102px;}
.headerunautha  a.nav-link {padding:0 !important;color:#BFBFD6;font-size:18px;line-height:24px;font-weight:500;font-family:'Outfit';transition:all ease-in-out 0.3s;position: relative;}
.headerunautha  a.nav-link::after{content: "";border: 1px solid transparent;right: 0;position: absolute;width: 0;bottom: -5px;transition:  all ease-in-out 0.5s;}
.headerunautha  a.nav-link::before{content: "";border: 1px solid transparent;left: 0;width: 0;position: absolute;bottom: -5px;transition:  all ease-in-out 0.5s;}
.headerunautha  a.nav-link:hover::before, .headerunautha  a.nav-link:hover::after{width: 50%;transition:  all ease-in-out 0.5s;border: 1px solid #BFBFD6;}
.headerunautha  a.nav-link:hover{color: #fff;}
.headerunautha ul li.headerunautha-gap { margin-left: 70px;}


@media (max-width:1780px) {
    .headerunautha ul li.headerunautha-gap {margin-left: 65px;}
    .headerunautha a.nav-link { font-size: 17px; line-height: 22px;}
}

@media (max-width:1580px) {
    .headerunautha ul li.headerunautha-gap {margin-left: 60px;}
}

@media (max-width:1399px) {
    .headerunautha a.nav-link {font-size: 16px;line-height: 21px;}
    .headerunautha ul li.headerunautha-gap { margin-left: 55px; }
}

@media (max-width:1199px) {
    .headerunautha .header-logo a.navbar-brand img { width: 90px;}
    .headerunautha ul li.headerunautha-gap { margin-left: 50px;}
    .headerunautha a.nav-link {font-size: 15px;line-height: 20px;}
}

@media (max-width:991px) {
    .headerunautha .header-logo a.navbar-brand img { width: 75px;}
    .headerunautha ul li.headerunautha-gap { margin-left: 0;}
}

@media (max-width:767px) {
    .headerunautha .header-logo a.navbar-brand img { width: 70px;}
}

@media (max-width:575px) {
    .headerunautha .header-logo a.navbar-brand img { width: 65px;}
}

@media (max-width:480px) {
    .headerunautha .header-logo a.navbar-brand img { width: 60px;}
}

@media (max-width:320px) {
    .headerunautha .header-logo a.navbar-brand img { width: 55px;}
    .headerunautha { padding: 5px 0px;}
}
