.transaction-history{margin-bottom: 200px;}
.transaction-history .all-select{ margin-bottom: 46px;}
.transaction-history .select-history label{font-weight: 400;font-size: 16px;line-height: 24px;
color: #717A8B;margin-bottom: 10px;}
.transaction-history .select-history { min-width: 240px; margin-right:20px;}
.transaction-history .select-history.all-selects { min-width: 155px;}
.transaction-history .history-input{ background-color: #29313C; border: none; color: #fff;height: 56px;}
.transaction-history .history-input:focus { color: #fff; background-color:#29313C; outline: none; box-shadow:none;}

@media (max-width: 1580px){
    .transaction-history .select-history label { font-size: 15px; line-height: 22px;}
    .transaction-history .all-select{ margin-bottom: 40px;}
    .select-history { margin-bottom: 20px;}
    .transaction-history ul.main-tabs-account.history { margin-bottom: 42px;}
    .transaction-history { margin-bottom: 170px;}
}

@media (max-width: 1399px){
    .transaction-history .select-history label { font-size: 14px; line-height: 20px;}
    .transaction-history .history-input {font-size: 15px;height: 38px;}
    .transaction-history ul.main-tabs-account.history { margin-bottom: 25px;}
    .transaction-history .select-history { min-width: 220px; margin-right: 17px;}
    .transaction-history .all-select { margin-bottom: 25px;}
    .transaction-history .account-tab li.nav-item{ margin-bottom: 15px;}
    .transaction-history { margin-bottom: 150px;}
}

@media (max-width: 1199px){
    .transaction-history .select-history label {font-size: 13px;line-height: 18px;}
    .transaction-history .select-history {min-width: 200px;margin-right: 15px;}
    .transaction-history ul.main-tabs-account.history { margin-bottom: 20px;}
    .transaction-history .all-select { margin-bottom: 20px;}
    .transaction-history { margin-bottom: 120px;}
}

@media (max-width:991px){
    .transaction-history .history-input { font-size: 14px;}
    .transaction-history .select-history { min-width: 180px; margin-right: 10px;}
    .transaction-history .all-select { margin-bottom: 10px;}
    .transaction-history ul.main-tabs-account.history { margin-bottom: 5px;}
    .transaction-history .select-history {min-width: 170px;margin-right: 10px;margin-bottom: 10px;}
    .transaction-history .select-history.all-selects { min-width: 140px;}
    .transaction-history { margin-bottom:100px;}
}

@media (max-width:767px){
    .transaction-history { margin-bottom:90px;}
}

@media (max-width:575px){
    .transaction-history { margin-bottom:80px;}
}

@media (max-width:320px){
    .transaction-history .select-history{ width: 100%;}
}