.stakingaccount{margin-bottom: 20px;}
.stakingaccount .green button{font-size: 16px;font-weight: 500;line-height: 22px;background:#2EBD85;height: 40px;width: 111px;}
.stakingaccount span.bg-gray-table {background: #717A8B;height: 40px;width: 76px;border-radius: 5px;}
.stakingaccount .staking-heading h3{font-size: 40px;line-height: 45px;font-weight: 700;}
.stakingaccount .staking-icon{background-color:#37404C;height:50px;width:50px;border-radius:5px;position:absolute;top:0;border-color:#37404C;transition: all ease-in-out 0.3s;}
.stakingaccount .staking-icon:hover{background-color:#0095C8;border-color:#0095C8;}
.stakingaccount .main-staking-heading{position: relative; margin-bottom: 25px;}
.stakingaccount .lock-icon {background-color:#37404C;height:60px;width:93px;border-radius: 5px;}
.stakingaccount .lock-icon .lock-i{font-size: 25px;}
.stakingaccount .staking-table-img {width: 40px;height: 40px;margin-right: 15px;}
.stakingaccount ul.main-tabs-account {display: flex;justify-content: flex-end;align-items: center;position: absolute;right: 0;z-index: 1;}
.stakingaccount .account-tab {position: relative;}
.stakingaccount .account-tab .main-tabs-account button.active {font-size: 25px;padding: 17px 36px;}
.stakingaccount .account-tab .main-tabs-account button{font-size: 25px;padding: 17px 36px;}
/* New-modal */
.modal-title.h4 h4.modal-heading{width:100%;display:flex;align-items: center;position:relative;margin-bottom: 30px;font-size:24px;font-weight:500;line-height:30px;}
.modal-content{background: #1F2630 !important;}
.modal-header{border-bottom: none !important; padding: 0 !important;}
.modal-body{ padding: 0 !important;}
.modal-content{ width: 630px !important;padding:60px !important;}
.modal-body .staking-time span{font-size: 12px;line-height: 16px;font-weight: 500;}
.modal-body .staking-day strong{font-size: 16px;font-weight: 500;}
.modal-body .main-staking-data{margin-bottom: 23px;}
.modal-body .border-circle{border: 2px solid #FFFFFF1A;width: 460px;margin: 0 auto;position: relative;margin-bottom: 8px;}
.modal-body .border-circle::after{content:'';height:10px;width:10px;border-radius:50%;background-color:#0095C8;position:absolute;top:-5px;left:-5px;}
.modal-body .border-circle::before{content:'';height:10px;width:10px;border-radius:50%;background-color:#0095C8;position:absolute;right:-5px;top:-5px;}
.modal-body .start-end-date span{font-size: 14px;line-height: 20px;font-weight: 500;margin-bottom: 30px;}
.modal-body h5{font-size:18px;line-height:24px;font-weight:500;margin-bottom: 10px;}
.modal-body button.staking-modal-button{width:100%;height:54px;background-color:#29313C;border-color:#29313C;font-size:16px;line-height:22px;font-weight:500;}
.modal-body .main-staking-modal{margin-bottom: 30px;}
.modal-body .amout-box{background-color:#29313C;padding: 21px 30px;border-radius:8px;margin-bottom:8px;}
.modal-body .amout-box strong{font-size: 16px;line-height: 22px;font-weight: 400;}
.modal-body .amout-box span{font-size: 16px;line-height: 22px;font-weight: 500;color:#0095C8;}
.modal-body .main-amout-box{margin-bottom: 30px;}
.modal-body .muto-max{margin-bottom: 20px;}
.modal-body button.confirm{background-color:#2EBD85;font-size:18px;line-height:24px;font-weight:500;border-color: #2EBD85;padding: 15px;margin-bottom: 21px;}
.modal-body .modal-check-box input{background-color: transparent;border: 3px solid #29313C;height: 20px;width: 20px;margin: 0;}
.modal-body .modal-check-box input:focus{box-shadow: none;outline: none;border-color: none;}
.modal-body .main-check-box span{color: #717A8B;font-size:14px;line-height:19px;font-weight:400;}



@media (max-width:1780px){
  .stakingaccount .staking-heading h3 {font-size: 38px; line-height: 44px;}
  .stakingaccount .account-tab .main-tabs-account button.active {font-size: 23px;padding: 15px 34px;}
  .stakingaccount .account-tab .main-tabs-account button{font-size: 23px;padding: 15px 34px;}
}

@media (max-width:1580px){
  .stakingaccount .staking-heading h3 {font-size: 35px;line-height: 40px;}
  .stakingaccount .account-tab .main-tabs-account button.active {font-size: 21px;padding: 14px 32px;}
  .stakingaccount .account-tab .main-tabs-account button{font-size: 21px;padding: 14px 32px;}
  .stakingaccount .staking-icon {height: 45px;width: 45px;}
  .stakingaccount span.bg-gray-table {height: 38px;width: 70px;}
  .stakingaccount .green button {height: 39px;width: 105px;}
}
@media (max-width:1399px){
  .stakingaccount .staking-heading h3 {font-size: 32px;line-height: 38px;}
  .stakingaccount .staking-icon {height: 42px;width: 42px;}
  .stakingaccount .account-tab .main-tabs-account button.active {font-size: 20px;padding: 12px 25px;}
  .stakingaccount .green button {font-size: 15px;line-height: 20px;height: 38px;width: 100px;}
  /* new-modal */
  .modal-content {width: 600px !important;padding: 55px !important;}
  .modal-body .start-end-date span {font-size: 13px;line-height: 18px;margin-bottom: 25px;}
  .modal-body .staking-day strong {font-size: 15px;}
  .modal-title.h4 h4.modal-heading {margin-bottom: 28px;font-size: 22px;line-height: 28px;}
  .modal-body .main-staking-data {margin-bottom: 20px;}
  .modal-body h5 {font-size: 17px;line-height: 22px;}
  .modal-body button.staking-modal-button {height: 50px;font-size: 15px;line-height: 20px;}
  .modal-body .main-staking-modal {margin-bottom: 28px;}
  .modal-body .amout-box strong {font-size: 15px;line-height: 20px;}
  .modal-body .amout-box {padding: 18px 28px;}
  .modal-body .main-amout-box {margin-bottom: 28px;}
  .modal-body .amout-box span {font-size: 15px;line-height: 20px;}
  .modal-body button.confirm {font-size: 17px;line-height: 22px;padding: 13px;margin-bottom: 20px;}
  }
  
@media (max-width:1199px){
  .stakingaccount .staking-heading h3 {font-size: 30px; line-height: 35px;}
  .stakingaccount .staking-icon {height: 40px;width: 40px;font-size: 15px;}
  .stakingaccount .account-tab .main-tabs-account button.active {font-size: 18px;padding: 10px 24px;}
  .stakingaccount .account-tab .main-tabs-account button{font-size: 18px;padding: 10px 24px;}
  .stakingaccount span.bg-gray-table {height: 35px;width: 65px;}
  .stakingaccount .green button {font-size: 14px;line-height: 18px;height: 37px;width: 90px;}
  /* New-modal */
  .modal-content {width: 570px !important;padding: 50px !important;}
  .modal-title.h4 h4.modal-heading {margin-bottom: 25px;font-size: 21px;line-height: 26px;}
  .modal-body .staking-day strong {font-size: 14px;}
  .modal-body .border-circle {width: 420px;}
  .modal-body .start-end-date span {font-size: 12px;line-height: 16px;margin-bottom: 20px;}
  .modal-body h5 {font-size: 16px;line-height: 20px;}
  .modal-body button.staking-modal-button {height: 45px;font-size: 14px;line-height: 18px;}
  .modal-body .main-staking-modal {margin-bottom: 25px;}
  .modal-body .amout-box {padding: 15px 28px;}
  .modal-body .amout-box strong {font-size: 14px;line-height: 18px;}
  .modal-body .amout-box span {font-size: 14px;line-height: 18px;}
  .modal-body .main-amout-box {margin-bottom: 25px;}
  .modal-body button.confirm {font-size: 16px;line-height: 20px;padding: 12px;}
  .modal-body .main-check-box span {font-size: 13px;line-height: 19px;}
  .modal-body .modal-check-box input {height: 18px;width: 18px;}
  }
  
@media (max-width:991px){
  .stakingaccount .staking-icon {height: 35px;width: 35px;font-size: 14px;}
  .stakingaccount .staking-heading h3 {font-size: 25px;line-height: 30px;}
  .stakingaccount .account-tab .main-tabs-account button.active {font-size: 15px;padding: 8px 20px;}
  .stakingaccount .account-tab .main-tabs-account button{font-size: 15px;padding: 8px 20px;}
  .stakingaccount span.bg-gray-table {height: 30px;width: 60px;}
  .stakingaccount .green button {font-size: 13px;line-height: 16px;height: 34px;width: 75px;}
  /* New-modal */
  .modal-content {width: 540px !important;padding: 40px !important;}
  .modal-title.h4 h4.modal-heading {margin-bottom: 20px;font-size: 20px;line-height: 25px;}
  .modal-body .staking-day strong {font-size: 13px;}
  .modal-body .border-circle {width: 410px;}
  .modal-body h5 {font-size: 15px;line-height: 19px;}
  .modal-body button.staking-modal-button {height: 42px;font-size: 13px;line-height: 17px;}
  .modal-body .main-staking-modal {margin-bottom: 20px;}
  .modal-body .amout-box strong {font-size: 13px;line-height: 17px;}
  .modal-body .amout-box span {font-size: 13px;line-height: 17px;}
  .modal-body .main-amout-box {margin-bottom: 20px;}
  .modal-body button.confirm {font-size: 15px;line-height: 19px;padding: 11px;}
  }
  
@media (max-width:767px){
  .stakingaccount .staking-heading h3 {font-size: 22px;line-height: 26px;}
  .stakingaccount .staking-icon {height: 30px;width: 30px;font-size: 11px;}
  .stakingaccount .account-tab .main-tabs-account button.active {font-size: 14px;padding: 6px 18px;}
  .stakingaccount .account-tab .main-tabs-account button{font-size: 14px;padding: 6px 18px;}
  .stakingaccount .staking-table-img {width: 35px;height: 35px;margin-right: 12px;}
  .stakingaccount span.bg-gray-table {height: 28px;}
  .stakingaccount .green button {font-size: 12px;line-height: 16px;height: 30px;width: 70px;}
  /* New-modal */
  .modal-content {width: 495px !important;padding: 30px !important;}
  .modal-title.h4 h4.modal-heading {margin-bottom: 18px;font-size: 19px;line-height: 24px;}
  .modal-body .staking-day strong {font-size: 12px;}
  .modal-body button.staking-modal-button {height: 40px;font-size: 12px;line-height: 16px;margin-bottom: 10px;}
  .modal-body .main-staking-modal {margin-bottom: 18px;}
  .modal-body .border-circle {width: 385px;}
  .modal-body .amout-box {padding: 13px 25px;}
  .modal-body .amout-box strong {font-size: 12px;line-height: 16px;}
  .modal-body .amout-box span {font-size: 12px;line-height: 16px;}
  .modal-body h5 {font-size: 14px;line-height: 18px;}
  }
  
@media (max-width:575px){
  .stakingaccount .staking-heading h3 {font-size: 21px; line-height: 25px;}
  .stakingaccount .staking-table-img {width: 30px;height: 30px;margin-right: 10px;}
  /* New-modal */
  .modal-content {width: 450px !important;padding: 25px !important;}
  .modal-title.h4 h4.modal-heading {margin-bottom: 15px;font-size: 18px;line-height: 22px;}
  .modal-body .border-circle {width: 350px;}
  .modal-body .main-staking-data {margin-bottom: 15px;}
  .modal-body .start-end-date span {margin-bottom: 15px;}
  .modal-body button.confirm {font-size: 14px;line-height: 18px;padding: 10px;}
  .modal-body .main-check-box span {font-size: 12px;line-height: 16px;}
  .modal-body .amout-box {padding: 13px 20px;}
  }
  
@media (max-width:480px){
  .stakingaccount .staking-heading h3 {font-size: 20px; line-height: 24px;}
  /* New-modal */
  .modal-content {width: 290px !important;padding: 20px !important;}
  .modal-body .border-circle {width: 200px;}
  .modal-body .amout-box {padding: 13px 18px;}
  }

@media (max-width: 320px){ 
  /* New-modal */
  .modal-content {width: 250px !important;padding: 18px 15px !important;}
  .modal-body .border-circle {width: 160px;}
  .modal-body button.confirm {font-size: 12px;line-height: 16px;padding: 11px;}
  .modal-body .amout-box {padding: 13px 15px;}
  }


