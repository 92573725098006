.footerunauth{padding: 43px 0 0 0;position: relative;}
.footerunauth::after {position:absolute;content:"";left:0;background-image: url(../../assets/images/footer-bg2.png);background-repeat:no-repeat;z-index:1;width:400px;height:400px;bottom:0;border-radius:50%;}
.footerunauth .footerunauth-logo{width: 156px;margin: 0 auto 45px;}
.footerunauth .main-footerunauth{position: relative;z-index: 2;}
.footerunauth .main-footerunauth.unorder-list{margin-bottom: 63px;}
.footerunauth li.footerunauth-list-items{margin-right: 23px;}
.footerunauth a.footerunauth-icon{font-size: 20px;border: 1px solid #012057;border-radius: 50%;height: 45px;width: 45px;background-color:#012057;transition: all ease-in-out 0.3s;}
.footerunauth a.footerunauth-icon:hover{border: 1px solid #009CDC;background-color: #009CDC;}
.footerunauth .footerunauth-anchr li{border-right: 1px solid #A2E2F95C;padding: 0px 40px;}
.footerunauth .footerunauth-anchr li a.nav-link{font-size: 16px;line-height: 23px;font-weight: 400;}
.footerunauth .footerunauth-anchr li:last-of-type{ border: none;}
.footerunauth ul.list-unstyled{padding:0 0 41px 0;border-bottom: 1px solid #A2E2F95C;}
.footerunauth .footerunauth-anchr-text{padding: 23px;}
.footerunauth .footerunauth-anchr-text p{font-size:16px;line-height:22px;font-weight: 400;color:#BFBFD6;margin: 0;}

@media (max-width:1780px) {
    .footerunauth .footerunauth-logo {width: 145px;margin: 0 auto 40px;}
    .footerunauth .main-footerunauth.unorder-list {margin-bottom: 60px;}
    .footerunauth .footerunauth-anchr li a.nav-link {font-size: 15px;  line-height: 22px;}
    .footerunauth ul.list-unstyled {padding: 0 0 38px 0;}
}

@media (max-width:1580px) {
    .footerunauth .footerunauth-logo { width: 140px; margin: 0px auto 38px;}
    .footerunauth a.footerunauth-icon {font-size: 19px;height: 43px;width: 43px;}
    .footerunauth .main-footerunauth.unorder-list { margin-bottom: 55px;}
}


@media (max-width:1399px) {
    .footerunauth {padding: 40px 0 0 0;}
    .footerunauth .footerunauth-logo { width: 135px; margin: 0px auto 35px;}
    .footerunauth a.footerunauth-icon { font-size: 17px; height: 40px; width: 40px;}
    .footerunauth li.footerunauth-list-items { margin-right: 20px;}
    .footerunauth .footerunauth-anchr li a.nav-link {font-size: 14px;line-height: 20px;}
    .footerunauth ul.list-unstyled { padding: 0px 0 35px 0;}
    .footerunauth .footerunauth-anchr-text p {font-size: 15px;line-height: 21px;}
}

@media (max-width:1199px) {
    .footerunauth .footerunauth-logo {width: 125px;margin: 0px auto 30px; }
    .footerunauth a.footerunauth-icon {font-size: 16px;height: 38px;width: 38px; }
    .footerunauth li.footerunauth-list-items { margin-right: 18px;}
    .footerunauth .footerunauth-anchr li a.nav-link {font-size: 13px;line-height: 19px;}
    .footerunauth .footerunauth-anchr li { padding: 0px 30px;}
    .footerunauth .footerunauth-anchr-text p {font-size: 14px;line-height: 20px;}
    .footerunauth .footerunauth-anchr-text {padding: 20px;}
}

@media (max-width:991px) {
    .footerunauth .footerunauth-logo {width: 100px;margin: 0px auto 25px; }
    .footerunauth a.footerunauth-icon {font-size: 16px;height: 35px;width: 35px;}
    .footerunauth li.footerunauth-list-items { margin-right: 15px; }
    .footerunauth .main-footerunauth.unorder-list { margin-bottom: 40px;}
    .footerunauth .footerunauth-anchr li a.nav-link {font-size: 12px;line-height: 18px;}
    .footerunauth .footerunauth-anchr li {padding: 0px 25px;}
    .footerunauth .footerunauth-anchr-text p {font-size: 13px;line-height: 19px;}
}

@media (max-width:767px) {
    .footerunauth {padding: 30px 0 0 0;}
    .footerunauth .footerunauth-logo {width: 90px;}
    .footerunauth li.footerunauth-list-items {margin-right: 10px;}
    .footerunauth a.footerunauth-icon {font-size: 14px;height: 30px;width: 30px;}
    .footerunauth .footerunauth-anchr li {padding: 0px 14px;}
    .footerunauth .footerunauth-anchr-text p {font-size: 12px;line-height: 18px; }
}

@media (max-width:575px) {
    .footerunauth .footerunauth-logo { width: 80px;}
    .footerunauth .footerunauth-anchr li {padding: 0px 8px;}
}

@media (max-width:480px) {
    .footerunauth .footerunauth-anchr li {padding: 0px 8px;margin-bottom: 20px;}
}

