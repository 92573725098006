.not-fount-main{background-color:#1F2630;padding:50px 100px;height:100vh;}
.not-fount-main  a.not-found-logo img{width: 100px;margin-bottom: 50px;}
.not-fount-main .man-img {width:150px;margin:0 auto 50px;} 
.not-fount-main h1{font-size:99px;line-height:99px;font-weight:700;font-family:"Formula Condensed";text-transform:uppercase;letter-spacing:3.76px;margin-bottom:20px;}
.not-fount-main strong{font: normal normal 300 36px/36px Assistant!important;letter-spacing: 0;color: #fafbff;text-align: center;}


@media (max-width:1399px){
    .not-fount-main .man-img {width:120px;margin:0 auto 35px;}
    .not-fount-main { padding: 40px 80px;}
}

@media (max-width:767px){
    .not-fount-main { padding: 30px 60px;}
}

@media (max-width:480px){
    .not-fount-main {padding: 20px 10px;}
    .not-fount-main strong{font: normal normal 300 32px/36px Assistant!important;}
}