.header{ background-color: #29313C;padding: 5px 0px;}
.header .header-logo{ width: 50%;}
.header a.navbar-brand img{ width: 100px;}
.header a.navbar-brand { margin-right: 27px;}
.header a.nav-link{ color: #fff; font-size: 16px; font-weight: 400;line-height: 20px;padding: 0 !important;margin-right: 50px;transition: all ease-in-out 0.3s;}
.header a.nav-link:hover{color:#0095C8 ;}
.main-header { width: 50%;}
.header li.header-list-icon{ margin-left: 23px;}
.header button.header-button{ background-color: #37404C; border: none; font-size: 16px; font-weight: 500;line-height: 20px;padding: 10px 30px;min-width: 142px;color: #0095C8;}
.header button.list{background-color: transparent;border: none;padding: 0;}
.header button.list img{width:27px;height:auto;}
.header button.list .hover-img,
.header button.list:hover .default-img{display: none;}
.header button.list:hover .hover-img{display:block}
.header .navbar-collapse { flex-grow: 0;}
.header .eseaking-modal a{background-color: transparent;font-size: 16px;font-weight: 400;line-height: 20px;border: none;margin-right: 43px; color: #fff;
transition: all ease-in-out 0.3s;}
.header .eseaking-modal a:hover{ background-color: transparent;color:#0095C8;}
.header button.list-disconnect{background: #37404C;border: 1px solid #37404C;padding: 10px 15px; transition: all ease-in-out 0.3s;font-size: 16px;line-height: 20px;font-weight: 400;}
.header button.list-disconnect:hover{background-color: #0095C8;}

/* @media qurry */

@media (max-width:1580px) {
    .header a.nav-link { font-size: 15px; line-height: 19px; margin-right: 47px;}
    .header button.header-button {font-size: 15px;font-weight: 500;line-height: 19px;padding: 9px 29px;min-width: 140px;}
    .header li.header-list-icon { margin-left: 22px;}
}

@media (max-width:1399px){
    .header a.nav-link {font-size: 14px;line-height: 18px;margin-right: 40px;}
    .header button.header-button { font-size: 14px;line-height: 18px; min-width: 125px;}
    .header a.navbar-brand img { width: 60px;}
    .header a.nav-link { margin-right: 20px;}
    .header .eseaking-modal a {font-size: 14px; line-height: 19px; margin-right: 20px;}
    .header button.list-disconnect{padding: 9px 14px;font-size: 15px;line-height: 19px;}
}

@media (max-width:1199px){
    .header a.nav-link {font-size: 12px;line-height: 18px;margin-right: 15px;}
    .header button.header-button { font-size: 13px; line-height: 18px; min-width: 110px; padding: 9px 23px;}
    .header .eseaking-modal a {font-size: 12px;line-height: 18px;margin-right: 15px;}
    .header button.list-disconnect{padding: 8px 13px;font-size: 14px;line-height: 18px;}
}

@media (max-width:991px){
    .header button.navbar-toggler  { color: #0095C8;position: absolute;top: 14px;right: 0;}
    .header .navbar-toggler:focus { box-shadow: none;}
    .header .header-logo { 
        /* width: 100%; margin-bottom: 15px; */
        justify-content: space-between;}
    .main-header {width: auto;margin: 0 40px 0 0;}
    /* .eseaking-modal {display: flex;justify-content: end;align-items: center;width: 100%;} */
    .header button.list-disconnect{padding: 7px 12px;font-size: 13px;line-height: 17px;}
   
   
}

@media (max-width:575px){
    .header {padding: 0px 0px;}
    .header button.header-button {font-size: 12px;line-height: 16px;min-width: 100px;padding: 8px 18px;}
    .header button.list.btn.btn-primary img { width: 20px;}
    .header button.toggle-icon { height: 30px;  width: 30px;top: -35px;}
    .header button.list-disconnect{padding: 6px 10px;font-size: 12x;line-height: 16px;}
}

@media (max-width:480px){
    .header a.navbar-brand {margin-right: 10px;}
    .header .header-logo { width: 29%;}
    .header button.header-button { min-width: 80px; padding: 7px 12px;}
    .header button.list.btn.btn-primary img { width: 15px;}
    .header li.header-list-icon { margin-left: 15px;}
}

@media (max-width:320px){
    
}





