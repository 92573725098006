@font-face {
  font-family: 'Poppins'; src: url('../src/assets/fonts/Poppins-Bold.woff2') format('woff2'),
  url('../src/assets/fonts/Poppins-Bold.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}

@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-Medium.woff2') format('woff2'),
  url('../src/assets/fonts/Poppins-Medium.woff') format('woff');font-weight: 500;font-style: normal;font-display: swap;}

@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-Black.woff2') format('woff2'),
  url('../src/assets/fonts/Poppins-Black.woff') format('woff');font-weight: 900;font-style: normal;font-display: swap;}

@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
  url('../src/assets/fonts/Poppins-SemiBold.woff') format('woff');font-weight: 600;font-style: normal;font-display: swap;}

@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-Regular.woff2') format('woff2'),
  url('../src/assets/fonts/Poppins-Regular.woff') format('woff');font-weight:normal;font-style:normal;font-display:swap;}

  @font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-Black.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-Black.woff') format('woff');font-weight: 900;font-style: normal;font-display: swap;}

@font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-SemiBold.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-SemiBold.woff') format('woff');font-weight: 600;font-style: normal;font-display: swap;}

@font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-ExtraBold.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-ExtraBold.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}

@font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-ExtraLight.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-ExtraLight.woff') format('woff');font-weight: 200;font-style: normal;font-display: swap;}

@font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-Thin.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-Thin.woff') format('woff');font-weight: 100;font-style: normal;font-display: swap;}

@font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-Medium.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-Medium.woff') format('woff');font-weight: 500;font-style: normal;font-display: swap;}

@font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-Bold.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-Bold.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}

@font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-Light.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-Light.woff') format('woff');font-weight: 300;font-style: normal;font-display: swap;}

@font-face {
    font-family: 'Outfit';
    src: url('../src/assets/fonts/Outfit-Regular.woff2') format('woff2'),
        url('../src/assets/fonts/Outfit-Regular.woff') format('woff');font-weight: normal;font-style: normal;font-display: swap;}


li{list-style: none;}
body{ font-family: 'Poppins';}
.unauth-routes{background:#02021A;overflow: hidden;}
.container-fluid { max-width: 1884px;}
.list-none{ list-style: none;}
.decoration-none{ text-decoration: none;}
button.btn-custom{background-color:#0E64C3;font-size:24px;line-height:28px;font-weight:400;font-family:'Outfit';padding:18px 35px;transition:all ease-in-out 0.3s;}
button.btn-custom:hover{background-color: #fff;color:#0E64C3;}
@media (min-width:1760px) {
  .container{max-width: 1684px;}
 }
/* .container{ max-width: 1684px;} */
.main-wrapper{background:#1F2630;}
.main-wrapper .main-wallet-overview{ width: calc(100% - 363px);}
.main-content{ padding-top: 60px;margin-bottom: 30px;}

/*navbar toggler-icon*/
.navbar-toggler-icon { background-image: url('./assets/images/toggler.svg');}
/*navbar toggler-icon end*/

.wallet-overview{border:1px solid#29313C;background:#29313C;padding:30px;border-radius: 10px;margin-bottom: 50px;}
.wallet-heading h3{ font-size: 30px; font-weight: 600; line-height: 35px; color: #fff; margin-bottom: 0;}
.wallet-heading { margin-bottom: 33px;}
.wallet-heading{ margin-bottom: 33px;}
span.value-span {color: #717A8B;font-size: 18px;font-weight: 500;line-height: 22px;margin-bottom: 10px;}
.wallet-heading a.transation-anchr{font-size:18px;line-height:25px;font-weight:600;padding: 15px 30px;display: flex;
background: #37404C;border: none; transition: all ease-in-out 0.3s;border-radius: 8px;}
.wallet-heading a.transation-anchr:hover{ background-color:#0095C8 ;}
.overview-storng {border: 1px solid#37404C;border-radius: 10px;padding: 17px 25px;background: #37404C;margin-right: 20px;
   /* width: 440px; */
  }
strong.pixd {font-size: 22px;font-weight: 600;line-height: 27px;color: #fff;margin-right: 19px;}
span.tusd { font-size: 18px; line-height: 24px; font-weight: 500; color: #717A8B;}
.account-tab .main-tabs-account button {background: #29313C;font-size: 18px;line-height: 25px;font-weight: 600;color: #fff;
border: none;padding: 14px 30px; border-radius: 5px;}
.account-tab .main-tabs-account button.active{background-color:#0095C8;color:#fff;}
.account-tab .main-tabs-account button:hover {background-color: #0095C8;color: #fff;}
table.table { min-width: 1100px;}
ul.main-tabs-account { border-bottom: none; margin-bottom: 20px;}
ul.main-tabs-account li.nav-item { margin-right: 20px;}
th.tabs-table-th { font-size: 16px; color: #717A8B; line-height: 20px; font-weight: 400;white-space:nowrap;}
.table th { border-bottom: none;}
td.euro {padding: 20px 8px;font-size:16px;line-height:22px;font-weight:500;width:250px;vertical-align:middle;white-space:nowrap;}
.heading-span { width: 100%;}
.account-pixd { margin: 0px 38px 12px 0px;}
.table-button button{font-size: 16px;line-height: 22px;font-weight:500;padding:5px 15px;border: none;margin-left:10px;
transition: all ease-in-out 0.3s;}
button.blue{ background: #0095C8;}
button.blue:hover{ background: #0095C8;}
button.red{ background: #F6465D;}
button.red:hover{ background: #F6465D;}
button.green{ background: #2EBD85;}
button.green:hover{ background: #2EBD85;}
.overview-storng.icon{ width: 178px;}
button.button-tab.btn.btn-primary {background:#29313C;border: none;padding:15px 30px;margin-right:27px;font-size: 18px;
line-height: 25px;font-weight: 600;}
.tabs-button.flex-wrap { margin-bottom: 30px;}
tr.text-white.line-bottom td { border-bottom: none;}
tr{ border-bottom: #29313C;}

/* select-style */
.transaction-history .wallet-select__control{background: #29313C;border: none;padding: 10px 12px;}
.transaction-history .wallet-select__control--is-focused{border: none;box-shadow: none;}
.transaction-history ul.main-tabs-account.history{margin-bottom: 49px;}
.transaction-history .select-history .wallet-select__single-value{color:#fff;font-size:18px;font-weight:500;margin:0;}
.transaction-history .wallet-select__indicator-separator{display: none;}
.transaction-history .wallet-select__menu-list{ padding: 0;}
.transaction-history .wallet-select__menu{border-radius: 5px;overflow: hidden;background-color: transparent !important;}
.transaction-history .wallet-select__option{ background-color:#29313C; color: #fff;}
.transaction-history .wallet-select__option.active{background-color:#37404C !important;}
.transaction-history .wallet-select__option:hover,.wallet-select__option--is-selected{background-color:#37404C !important;}
.transaction-history .wallet-select__indicator.wallet-select__dropdown-indicator svg{color: #fff;}
/* select-style end */

/* table scrollber */
*{scrollbar-width:thin;scrollbar-color:#c31977 #ccc}
::-webkit-scrollbar{width:5px;height:7px;border:none!important}
::-webkit-scrollbar-track{background:#f5cde3;border:none!important;border-radius: 5px;}
::-webkit-scrollbar-thumb{background-color:#0095C8;border:none;border-radius: 5px;}

/* home page */
.crypto-card-max-width {max-width: 1290px;margin: 0 auto;}
.heading-paragraphe h4{font-size: 62px;line-height: 70px;font-weight: 400;font-family: 'Outfit';margin-bottom: 49px;max-width: 450px;}
.heading-paragraphe p{font-size: 24px;line-height:35px;font-weight:400;color: #BFBFD6; max-width: 550px;}

@media (max-width: 1780px) {
  .transaction-history .history-input {height: 52px;}
  .transaction-history .wallet-select__control {padding: 9px 12px;}
  .main-wrapper .main-wallet-overview { width: calc(100% - 350px);}
  .wallet-heading h3 { font-size: 28px; line-height: 33px;}
  strong.pixd {font-size: 20px;line-height: 25px;margin-right: 17px;}
  span.tusd {font-size: 17px;line-height: 21px;}
  .account-tab .main-tabs-account button { font-size: 17px; line-height: 22px;}
  th.tabs-table-th {font-size: 15px;line-height: 19px;}
  /* home page */
 .crypto-card-max-width {max-width: 1200px;}
 .heading-paragraphe h4 {font-size: 60px;line-height: 68px;margin-bottom: 45px;}
 .heading-paragraphe p {font-size: 23px;max-width: 530px;line-height: 32px;}
}

@media (max-width:1580px) {
  .wallet-overview {padding:28px;margin-bottom: 48px;}
  .transaction-history .history-input{height: 50px;}
  .transaction-history .wallet-select__control{padding: 8px 12px;}
  .transaction-history .select-history .wallet-select__single-value { font-size: 17px;}
  .main-wrapper .main-wallet-overview { width: calc(100% - 345px);}
  .wallet-heading h3 { font-size: 27px;}
  .overview-storng { padding: 17px 20px;}
  .account-pixd { margin-right: 25px;}
  strong.pixd {font-size: 19px;line-height: 24px;margin-right: 16px;}
  span.tusd {font-size: 16px;line-height: 20px;}
  button.button-tab.btn.btn-primary{padding:13px 28px;margin-right:18px;line-height:22px;font-size:17px;}

  /* home page */
  .crypto-card-max-width { max-width: 1150px;}
  .heading-paragraphe p {font-size: 22px;max-width: 510px;line-height: 31px;}
  .heading-paragraphe h4 {font-size: 58px;line-height: 66px;margin-bottom: 43px;}
}

@media (max-width:1399px){
  strong.pixd {font-size: 15px;line-height: 19px;margin-right: 10px;}
  .overview span.tusd {font-size: 14px;line-height: 17px;}
  .wallet-overview {padding:15px;margin-bottom: 20px;}
  .transaction-history .select-history .wallet-select__single-value { font-size: 16px;}
  .main-wrapper .main-wallet-overview { width: calc(100% - 335px);}
  .wallet-heading h3 { font-size: 22px;}
  span.value-span { font-size: 17px; line-height: 21px; margin-bottom: 10px;}
  .overview-storng { padding:9px 10px 8px;}
  .account-pixd { margin-right: 15px; }
  .account-tab .main-tabs-account button,
  .startaccount .start-account-modal button{ font-size: 14px; line-height: 18px; padding: 8px 28px;}
  td.euro { font-size: 14px; line-height: 18px;}
  .account-pixd { margin-right: 15px; }
  .table-button button { font-size: 15px; padding: 5px 12px;}
  button.button-tab.btn.btn-primary{line-height: 20px; font-size: 16px;}
  .wallet-heading{margin-bottom:20px;}
  ul.main-tabs-account{margin-bottom:10px;}
  /* .main-content{padding-top:30px} */
  .wallet-heading a.transation-anchr{font-size:14px;line-height:19px;padding:10px 25px;}
  td.euro{padding:8px;}
   button.btn-custom {font-size: 23px;line-height: 28px;padding: 17px 32px;}
   /* home page */
   .crypto-card-max-width { max-width: 1000px;}
   .heading-paragraphe h4 {font-size: 55px;line-height: 62px;margin-bottom: 40px;}
   .heading-paragraphe p {font-size: 20px;max-width: 460px;line-height: 30px;}
}

@media (max-width:1199px){
  .overview span.tusd {font-size: 13px;line-height: 19px;}
  strong.pixd {font-size: 14px;line-height: 18px;margin-right: 10px;}
  .wallet-overview { padding:20px;margin-bottom: 35px;}
  .transaction-history .select-history .wallet-select__single-value { font-size: 14px;}
  .main-wrapper .main-wallet-overview { width: calc(100% - 280px);}
  .wallet-heading h3 { font-size: 23px;}
  span.value-span {font-size: 16px;line-height: 20px;}
  .overview-storng { padding: 12px; }
  span.tusd {font-size: 13px;line-height: 18px;}
  .value-game-account { margin-bottom: 15px;}
  .table-button button { font-size: 14px; padding: 4px 12px;}
  button.btn-custom {font-size: 22px;line-height: 26px;padding: 15px 25px;}
  /* home page */
 .crypto-card-max-width { max-width: 910px;}
 .heading-paragraphe h4 { font-size: 50px; line-height: 60px; margin-bottom: 35px;}
 .heading-paragraphe p {font-size: 18px;max-width: 415px;line-height: 30px;}
}

@media (max-width:991px){
  .wallet-overview {margin-bottom: 30px;}
  .select-history .wallet-select__single-value { font-size: 13px;}
  ul.navbar-nav.list-unstyled { padding: 10px;}
  ul.navbar-nav.list-unstyled li{background-color: #37404C;margin-bottom: 10px;padding: 10px;border-radius: 5px;}
  .navbar-toggler { border: none;padding: 0;}
  div#basic-navbar-nav {position: absolute;background: #29313C;width: 100%;top: 100%;z-index: 9;border-radius:8px;right: 0;}
  .main-wrapper .main-wallet-overview { width: calc(100% - 245px);}
  .wallet-heading h3 { font-size: 22px;}
  span.value-span { font-size: 15px; line-height: 19px;}
  .wallet-heading a.transation-anchr{ font-size: 16px; line-height: 22px;}
  .account-tab .main-tabs-account button { font-size: 15px; line-height: 19px; padding: 8px 25px;}
  .account-tab .main-tabs-account button.active{ font-size: 15px; line-height: 19px; padding: 8px 25px;}
  .table-button button { font-size: 13px; padding: 3px 11px;}
  ul.main-tabs-account li.nav-item  { margin-right: 18px;}
  button.button-tab.btn.btn-primary{font-size: 14px;line-height: 18px; padding: 9px 20px;}
  button.btn-custom {font-size: 20px;line-height: 25px;padding: 12px 20px;}
  .navbar-toggler:focus { box-shadow: none;}
  /* home page */
  .crypto-card-max-width { max-width: 100%;}
  .heading-paragraphe h4 {font-size: 38px;line-height: 45px;margin-bottom: 30px;}
  .heading-paragraphe p { font-size: 14px;line-height: 22px;max-width: 320px;}  
}

@media (max-width:767px){
  .main-wrapper .main-wallet-overview { width: 100%;}
  td.euro {font-size: 13px;line-height: 17px;}
  ul.main-tabs-account li.nav-item  { margin-right: 15px;}
  button.btn-custom {font-size: 18px;line-height: 23px;padding: 10px 18px;}
  /* home page */
  .heading-paragraphe h4 {font-size: 30px;line-height: 38px;margin-bottom: 25px;max-width: 100%;text-align: center;}
  .heading-paragraphe p {font-size: 13px;line-height: 20px;max-width: 100%;text-align: center;}
  .heading-paragraphe {margin-bottom: 50px;}
}

@media (max-width:575px){
 .overview h3 {font-size: 19px;text-align: center;}
  span.value-span.d-flex { justify-content: center;}
  .wallet-heading a.transation-anchr{ font-size: 14px; line-height: 20px; padding: 10px 19px; margin: 0 auto;}
  .overview-storng { margin: 0 auto;max-width: 100%;}
  ul.main-tabs-account li.nav-item  { margin-right: 13px;}
  .account-pixd { width: 100%; margin-right: 0;}
  button.btn-custom {font-size: 15px;line-height: 20px;padding: 9px 15px;}
  /* home page */
  .heading-paragraphe h4 {font-size: 25px;line-height: 30px;margin-bottom: 20px;}
  .heading-paragraphe p {font-size: 12px;line-height: 18px;}
} 

@media (max-width:480px){
  button.btn-custom {font-size: 13px;line-height: 20px;padding: 6px 12px;}
  strong.pixd {font-size: 12px;line-height: 17px;}
  .account-tab .main-tabs-account button {font-size: 13px; line-height: 17px; padding: 7px 20px;}
  .account-tab .main-tabs-account button.active {font-size: 13px; line-height: 17px; padding: 7px 20px;}
  ul.main-tabs-account li.nav-item { margin-right: 10px;}
  button.button-tab.btn.btn-primary {font-size: 13px; line-height: 19px; padding: 9px 20px;}
  .overview h3 {font-size: 18px;}
  /* home page  */
  .heading-paragraphe h4 {font-size: 20px;line-height: 25px;}

}

@media (max-width: 320px){ 
  button.btn-custom {font-size: 12px;line-height: 18px;padding: 5px 10px;}
  strong.pixd {display: block; margin: 0 auto 5px;}
  span.tusd {font-size: 12px;line-height: 16px;display: flex; margin: 0 auto;}
  /* home page  */
  .heading-paragraphe h4 {font-size: 17px;line-height: 23px; }
}