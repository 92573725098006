.swap-account ul.main-tabs-account{display: flex;justify-content: center;align-items: center;}
.swap-account .swap-tab{background: #37404C;width: 800px;margin: 0 auto;border-radius: 10px;padding: 30px;}
.swap-account .swap-tab span{font-size:16px;line-height:22px;font-weight:500;margin-bottom: 15px;}
.swap-account .swap-strong strong{font-size:22px;line-height:28px;font-weight:500;}
/* .swap-account .select-img {width: 25px;position: absolute;top: 9px;left: 15px;z-index: 1;} */
.swap-account .wallet-select__control { width: 300px; height: 44px; background-color: #29313C;border: 1px solid #29313C;}
/* .swap-account .wallet-select__value-container.wallet-select__value-container--has-value{padding: 0 0 0 60px;} */
/* .swap-account .select-swap.all-select {position: relative;} */

.swap-account span.lable-img img {width: 30px;}
.swap-account .wallet-select__single-value{color: #fff;font-size: 16px;line-height: 20px;}
.swap-account .wallet-select__control:hover{border-color:#29313C;}
.swap-account .main-swap-strong-select{ margin-bottom: 30px;}
.swap-account .swap-tab button.swap-icon{background-color:#29313C;padding:20px;border-radius:10px;color:#fff;font-size:25px;margin-bottom:40px;border-color: #29313C;}
.swap-account .swap-connect {background-color:#29313C; padding: 40px;border-radius: 10px;}
.swap-account .swap-connect button{background-color:#37404C;padding: 15px;border-color: #37404C;font-size: 18px;line-height: 23px;font-weight: 600;}
/* send swap */
.swap-account .send-strong strong{font-size: 30px;line-height: 35px;font-weight: 700;text-align: center;margin-bottom: 30px;}
.swap-account .main-swap-strong-select button{font-size: 20px;line-height:25px;font-weight:500;background-color:#29313C;border-color:#29313C;padding:9px 25px;}
.swap-account .swap-tab span.recipient{font-size: 18px;line-height:23px;font-weight:600;margin-bottom:20px;}
.swap-account .swap-tab input.swap-input.form-control{background:transparent;border:none;padding:0;font-size:25px;line-height:32px;font-weight:600;color:#0095C8;}
.swap-account .swap-tab input.swap-input.form-control::placeholder{color:#0095C8;}
.swap-account .swap-tab input.swap-input.form-control:focus {color: none;border-color: none;outline: 0;box-shadow: none;}

@media (max-width: 1580px){
    .swap-account .swap-tab {width: 750px;padding: 28px;}
    .swap-account .swap-tab span {font-size: 15px;line-height: 20px;}
    .swap-account .swap-strong strong {font-size: 21px;line-height: 26px;}
    .swap-account .main-swap-strong-select { margin-bottom: 25px;}
    .swap-account .wallet-select__single-value {font-size: 15px;line-height: 19px;}
    .swap-account .swap-tab button.swap-icon {padding: 18px;font-size: 22px;margin-bottom: 35px;}
    .swap-account .swap-connect {padding: 36px;}
    .swap-account .swap-connect button {padding: 14px;font-size: 17px;line-height: 22px;}
    /* send swap*/
    .swap-account .send-strong strong { font-size: 29px; line-height: 34px; margin-bottom: 28px;}
    .swap-account .swap-tab span.recipient {font-size: 17px;line-height: 22px;margin-bottom: 18px;}
    .swap-account .swap-tab input.swap-input.form-control {font-size: 24px; line-height: 30px;}
}

@media (max-width: 1399px){
    .swap-account .swap-tab {width: 700px;padding: 27px;}
    .swap-account .swap-tab span {font-size: 14px;line-height: 18px;}
    .swap-account .swap-strong strong { font-size: 20px; line-height: 25px;}
    .swap-account .main-swap-strong-select {margin-bottom: 22px;}
    .swap-account .swap-tab button.swap-icon { padding: 17px; font-size: 21px; margin-bottom: 30px;}
    .swap-account .wallet-select__control {width: 290px;}
    .swap-account .wallet-select__single-value {font-size: 14px;line-height: 18px;}
    .swap-account .swap-connect { padding: 35px;}
    .swap-account .swap-connect button {font-size: 16px;line-height: 21px;}
    /* send swap*/
    .swap-account .send-strong strong {font-size: 28px;line-height: 33px;margin-bottom: 25px;}
    .swap-account .swap-tab span.recipient {font-size: 16px;line-height: 20px;margin-bottom: 15px;}
    .swap-account .swap-tab input.swap-input.form-control {font-size: 23px;line-height: 29px;}
}

@media (max-width: 1199px){
    .swap-account .swap-tab {width: 650px;padding: 25px;}
    .swap-account .swap-strong strong {font-size: 19px;line-height: 24px;}
    .swap-account .wallet-select__single-value {font-size: 13px;line-height: 17px;}
    .swap-account .wallet-select__control {width: 280px;}
    .swap-account .main-swap-strong-select {margin-bottom: 20px;}
    .swap-account .swap-tab button.swap-icon {padding: 15px;font-size: 20px;margin-bottom: 28px;}
    .swap-account .swap-connect {padding: 32px;}
    .swap-account .swap-connect button {font-size: 15px;line-height: 20px;}
     /* send swap*/
    .swap-account .send-strong strong { font-size: 26px; line-height: 30px; margin-bottom: 20px;}
    .swap-account .swap-tab span.recipient {font-size: 15px;line-height: 19px;}
    .swap-account .swap-tab input.swap-input.form-control {font-size: 22px;line-height: 28px;}
}

@media (max-width: 991px){
    .swap-account .swap-tab {width: 450px;}
    .swap-account .swap-strong strong {font-size: 18px;line-height: 22px;}
    .swap-account .wallet-select__control {width: 250px; }
    .swap-account .swap-tab button.swap-icon {padding: 15px;font-size: 18px;margin-bottom: 25px;}
    .swap-account .swap-tab span {font-size: 13px;line-height: 17px;}
    .swap-account .swap-connect { padding: 30px;}
    .swap-account .swap-connect button {font-size: 14px;line-height: 19px;padding: 12px;}
    /* send swap*/
    .swap-account .send-strong strong { font-size: 25px; line-height: 30px; margin-bottom: 18px;}
    .swap-account .swap-tab span.recipient {font-size: 14px;line-height: 18px;}
    .swap-account .swap-tab input.swap-input.form-control {font-size: 20px;line-height: 26px;}
}

@media (max-width: 767px){
    .swap-account .swap-tab span { font-size: 12px; line-height: 16px;}
    .swap-account .swap-strong strong {font-size: 17px;line-height: 21px;margin-bottom: 15px;}
    .swap-account .wallet-select__control {width: 100%;}
    .swap-account .swap-tab button.swap-icon {padding: 13px;font-size: 17px;margin-bottom: 22px;}
    .swap-account .swap-connect {padding: 25px;}
    .swap-account .swap-connect button {font-size: 13px;line-height: 18px;padding: 12px;}
      /* send swap*/
    .swap-account .select-swap.all-select {width: 100%;}
    .swap-account .send-strong strong {font-size: 22px;line-height: 26px;margin-bottom: 15px;}
    .swap-account .main-swap-strong-select button { font-size: 15px; line-height: 20px;}
    .swap-account .swap-tab input.swap-input.form-control {font-size: 18px;line-height: 24px;}
}

@media (max-width: 575px){
    .swap-account .swap-strong strong {font-size: 16px;line-height: 20px;}
     /* send swap*/
    .swap-account .send-strong strong {font-size: 20px;line-height: 25px;}
    .swap-account .swap-tab span.recipient {font-size: 13px;line-height: 17px;}
    .swap-account .swap-tab input.swap-input.form-control {font-size: 17px;line-height: 22px;}
}

@media (max-width: 480px){
    .swap-account .swap-tab { width: 290px;padding: 15px;}
    .swap-account .wallet-select__control {height: 40px;}
    .swap-account .swap-tab button.swap-icon {padding: 12px;font-size: 16px;margin-bottom: 20px;}
    .swap-account .swap-connect {padding: 22px;}
    .swap-account .swap-connect button {font-size: 12px;line-height: 17px;padding: 11px;}
      /* send swap*/
    .swap-account .send-strong strong { font-size: 18px; line-height: 23px; }
    .swap-account .main-swap-strong-select button {font-size: 12px;line-height: 18px;}
    .swap-account .swap-tab span.recipient {font-size: 12px;line-height: 16px;margin-bottom: 10px;}
    .swap-account .swap-tab input.swap-input.form-control {font-size: 16px;line-height: 20px;}
}

@media (max-width: 320px){
    .swap-account .swap-tab { width: 250px; }
}
