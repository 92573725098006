#sidebar .overview-list {border: 1px solid #29313C;background: #29313C;padding: 28px 20px;border-radius: 10px;width: 323px;
position: relative;}
#sidebar span.overview-box { border: 1px solid #48515F; height: 24px; width: 24px; display: flex; background: #48515F;
margin-right: 20px;transition: all ease-in-out 0.3s;}
/* #sidebar li.box-span:hover {background: #0095C8;} */
#sidebar li.box-span:hover span.overview-box { border-radius: 50%;}
/* #sidebar li.box-span { background: #37404C; padding: 15px; border-radius: 5px; margin-bottom: 6px;
transition: all ease-in-out 0.3s;} */
#sidebar li a{background: #37404C;padding: 15px;border-radius:5px;margin-bottom:6px;transition:all ease-in-out 0.3s;}
#sidebar li a:hover {background: #0095C8;}
#sidebar li.box-span.active span.overview-box { border-radius: 50%;}
#sidebar a span.heading-overview { color: #fff; font-size: 16px; line-height: 20px; font-weight: 500;}
button.toggle-icon { height: 35px; width: 35px; border: none; border-radius: 4px; display: flex; justify-content: center;
align-items: center; position: absolute; right: -65px; top: -44px; display: none; background-color: #0095C8;}

@media (max-width: 1780px) {
    #sidebar .overview-list { width: 310px;}
    #sidebar span.overview-box { height: 22px; width: 22px;}
    #sidebar a span.heading-overview { font-size: 15px; line-height: 19px;}
    #sidebar .main-wallet-overview { width: calc(100% - 350px);}
}

@media (max-width: 1580px) {
    #sidebar .overview-list { padding: 25px 18px; width: 305px;}
    #sidebar a span.heading-overview { font-size: 14px; line-height: 18px;}
    #sidebar span.overview-box { height: 21px; width: 21px;}
}

@media (max-width: 1399px) {
    #sidebar .overview-list { padding: 20px 15px; width: 295px;}
    #sidebar li a { padding: 12px;}
}

@media (max-width:1199px) {
    #sidebar span.overview-box { height: 20px; width: 20px; margin-right: 10px;}
    #sidebar .overview-list { padding: 15px 15px; width: 265px;}
    #sidebar a span.heading-overview { font-size: 13px; line-height: 17px;}
}

@media (max-width: 991px) {
    #sidebar a span.heading-overview { font-size: 12px; line-height: 16px;}
    #sidebar .overview-list { width: 230px; }
}

@media (max-width: 767px) {
    /* #sidebar {  position: relative;} */
    #sidebar .overview-list {position: absolute;left: -245px;transition: all ease-in-out 0.3s;z-index: 1;}
    .main-wrapper.active #sidebar .overview-list { left: 10px;}
    #sidebar button.toggle-icon { display: block;}
}

@media (max-width:319px){
    button.toggle-icon {height: 23px;width: 23px;right: -40px;padding: 0;}
}

