.excchange-instantly {margin-bottom: 93px;}
.excchange-instantly .excchange-instantly-img{margin-left: -30px;}

@media (max-width:1780px) {
    .excchange-instantly { margin-bottom: 90px;}
    .excchange-instantly .excchange-instantly-img {margin-left: -25px;}
}

@media (max-width:1580px) {
    .excchange-instantly {margin-bottom: 85px;}
}

@media (max-width:1399px) {
    .excchange-instantly { margin-bottom: 80px;}
    .excchange-instantly .excchange-instantly-img {margin-left: -18px;}
}

@media (max-width:1199px) {
    .excchange-instantly .excchange-instantly-img { margin-left: -10px; }
}

@media (max-width:991px) {
    .excchange-instantly { margin-bottom: 70px;}
    .excchange-instantly .excchange-instantly-img {margin-left: 0; }
}

@media (max-width:767px) {
    .excchange-instantly { margin-bottom: 60px;}
    .excchange-instantly .excchange-instantly-img {margin-bottom: 50px;}
}

@media (max-width:575px) {
    .excchange-instantly { margin-bottom: 50px;}
}

@media (max-width:480px) {
   
}

@media (max-width:320px) {
    
}
