.staking{ padding: 100px;}
.staking a.not-found-logo{width: 100px;margin: 0 auto 30px;}
.staking .main-heading h1{font-size: 50px;line-height: 60px;font-weight: 700;margin-bottom:30px;}
.staking .main-heading span{font-size: 24px;font-weight: 500;line-height: 30px;margin-bottom: 60px;}
.staking .main-date-time{border: 1px solid #fff;width: 800px;padding: 30px;margin: 0 auto;}
.staking .main-date-time strong{font-size: 50px;line-height: 60px;font-weight: 500;}
.staking .main-date-time span{font-size: 18px;line-height: 25px;font-weight: 400;margin-bottom: 0;}
.staking .date-time {width: 100px;}

@media (max-width:1599px){
    .staking{ padding: 90px;}
    .staking .main-heading h1{font-size: 45px;line-height: 55px;margin-bottom:25px;}
    .staking .main-heading span{font-size: 22px;line-height: 28px;margin-bottom: 50px;}
    .staking .main-date-time{padding: 28px;}
    .staking .main-date-time strong{font-size: 45px;line-height: 55px;}
    .staking .main-date-time span{font-size: 17px;line-height: 23px;margin-bottom: 0;}
}

@media (max-width:1399px){
    .staking{ padding: 75px;}
    .staking .main-heading h1{font-size: 42px;line-height: 55px;margin-bottom:20px;}
    .staking .main-heading span{font-size: 21px;line-height: 26px;margin-bottom: 45px;}
    .staking .main-date-time{padding: 26px;}
    .staking .main-date-time strong{font-size: 40px;line-height: 50px;}
    .staking .main-date-time span{font-size: 16px;line-height: 20px;margin-bottom: 0;}
}

@media (max-width:1199px){
    .staking .main-heading h1{font-size: 40px;line-height: 50px;}
    .staking .main-heading span{font-size: 20px;line-height: 25px;margin-bottom: 40px;}
    .staking .main-date-time{padding: 24px;}
    .staking .main-date-time strong{font-size: 37px;line-height: 45px;}
    .staking .main-date-time span{font-size: 15px;line-height: 20px;margin-bottom: 0;}
}

@media (max-width:991px){
    .staking{ padding: 65px;}
    .staking .main-heading h1{font-size: 38px;line-height: 42px;}
    .staking .main-heading span{font-size: 19px;line-height: 24px;margin-bottom: 35px;}
    .staking .main-date-time{padding: 24px;width: 645px;}
    .staking .main-date-time strong{font-size: 35px;line-height: 42px;}
    .staking .main-date-time span{font-size: 14px;line-height: 20px;margin-bottom: 0;}
}

@media (max-width:767px){
    .staking{ padding: 34px;}
    .staking .main-date-time {width: 480px;padding: 20px;}
    .staking .main-heading h1{font-size: 35px;line-height: 40px;}
    .staking .main-heading span{font-size: 16px;line-height: 21px;margin-bottom: 30px;}
    .staking .main-date-time strong{font-size: 32px;line-height: 40px;}
    .staking .main-date-time span{font-size: 13px;line-height: 19px;margin-bottom: 0;}
}

@media (max-width:575px){
    .staking a.not-found-logo {width: 80px;margin: 0px auto 15px;}
    .staking .main-heading h1 {font-size: 32px;line-height: 36px;}
    .staking{ padding: 34px 10px;}
    .staking .main-heading span {font-size: 14px;line-height: 20px;margin-bottom: 25px;}
    .staking .main-date-time strong{font-size: 27px;line-height: 35px;}
    .staking .main-date-time span{font-size: 12px;line-height: 17px;margin-bottom: 0;}
    .staking .main-date-time { width: 440px; padding: 15px;}
}

@media (max-width:480px){
    .staking a.not-found-logo {width: 65px;}
    .staking .main-heading h1 {font-size: 30px;line-height: 36px;}
    .staking .main-date-time {width: 276px;padding: 10px;}
    .staking .main-heading span {font-size: 12px;line-height: 20px;margin-bottom: 20px;}
    .staking .main-date-time span{margin-bottom: 0;}
    .staking .main-date-time strong {font-size: 20px;line-height: 25px;}
}

@media (max-width:320px){
    .staking .main-date-time {width: 240px;padding: 10px;}
    .staking .main-date-time strong {font-size: 15px;line-height: 20px;}
}
