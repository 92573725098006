.browser-apps {margin-bottom: 186px;}
.browser-apps .browser-apps-img{ width: 766px;margin-left: -80px;}

@media (max-width:1780px) {
    .browser-apps {margin-bottom: 160px;}
    .browser-apps .browser-apps-img { width: 730px; margin-left: -70px;}
}

@media (max-width:1580px) {
    .browser-apps {margin-bottom: 140px;}
    .browser-apps .browser-apps-img { width: 100%; margin-left: -60px;}
}


@media (max-width:1399px) {
    .browser-apps { margin-bottom: 120px;}
    .browser-apps .browser-apps-img { margin-left: -30px;}
}

@media (max-width:1199px) {
    .browser-apps { margin-bottom: 100px;}
    .browser-apps .browser-apps-img { margin-left: -10px;}
}

@media (max-width:991px) {
    .browser-apps .browser-apps-img { margin-left: 0;}
    .browser-apps { margin-bottom: 80px;}
}

@media (max-width:767px) {
    .browser-apps { margin-bottom: 70px;}
    .browser-apps .browser-apps-img { margin-bottom: 50px;}
}

@media (max-width:575px) {
    .browser-apps { margin-bottom: 60px;}
}

@media (max-width:480px) {
    .browser-apps { margin-bottom: 50px;}
}

@media (max-width:320px) {
}
