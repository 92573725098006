.crypto-card {margin-bottom: 101px;}
.crypto-card  .crypto-card-img { width: 691px; margin: 0 auto;}

@media (max-width:1780px) {
    .crypto-card { margin-bottom: 95px;}
    .crypto-card .crypto-card-img {width: 670px;}
}

@media (max-width:1580px) {
    .crypto-card .crypto-card-img {width: 100%; }
}

@media (max-width:1399px) {
    .crypto-card { margin-bottom: 90px;}
}

@media (max-width:1199px) {
    .crypto-card {margin-bottom: 80px;}
}

@media (max-width:991px) {
    .crypto-card {margin-bottom: 70px; }
}

@media (max-width:767px) {
    .crypto-card { margin-bottom: 65px; }
    

}

@media (max-width:575px) {

}

@media (max-width:480px) {
    .crypto-card {margin-bottom: 60px;}
    
}

@media (max-width:320px) {
   
}
