.overview .container{ max-width: 1684px;}
.overview .wallet-heading{ margin-bottom: 33px;}
.overview span.tusd{font-size:18px ;line-height: 24px;font-weight:500 ;color:#717A8B ;}
.overview span.circle{ border: 10px solid #37404C; height: 178px; width: 178px;border-radius: 50%; display: flex;}
.overview span.border-box{height:12px;width:12px;border-radius:2px;margin-right: 7px;}
.overview span.red-color{ border:1px solid #F6465D;background:#F6465D;}
.overview .box-strong{ margin:0px 39px 0px 36px;}
.overview strong.eth{ font-size:16px; font-weight: 600; line-height:20px ; color: #fff; width: 25px;}
.overview span.one-number{color: #fff;font-size:16px ;font-weight:500 ;line-height:20px;white-space: nowrap;}
.overview .light-blue-color{ border: 1px solid#0095C8; background: #0095C8;}
.overview .jamun-color{color: #7B61FF;background-color: #7B61FF;}
.overview .green-color{color: #2EBD85;background-color: #2EBD85;}
.overview  .bule-green-color{ color: #0095C8 ;background-color: #0095C8;}
/* .overview h3.accounts{font-size: 24px; font-weight:600 ; line-height: 30px;margin-bottom: 18px;} */
/* .overview h5{ font-size: 18px; font-weight: 600; line-height: 24px;margin-bottom: 20px;} */
/* .overview span.three-pixp{font-size:14px; font-weight: 500; line-height: 18px;} */
/* .overview .start-box{ width:calc(25% - 5px);background:#29313C ; border: 1px solid #29313C ; padding: 30px 20px;border-radius: 10px;
margin:0 5px 5px 0;} */
/* .overview strong.five-busd{ background:#37404C ; border: 1px solid #37404C; padding: 15px; margin-right: 10px;
border-radius: 5px;color: #0095C8; font-size:16px; font-weight:600; line-height: 20px;} */
/* .overview .overview-account{ margin-bottom: 15px;} */
/* .overview button.transfer.btn{background: #0095C8;border: 1px solid #0095C8;width: 100%;} */
.overview .account-main{ margin-bottom: 272px;}
.overview li.list-overview.list-m{ margin-bottom: 15px;}


/* @media qurry */

@media (max-width:1780px) {
    .overview .wallet-heading{ margin-bottom: 30px;}
    .overview span.tusd {font-size: 17px; line-height: 21px;}
    .overview span.circle { height: 170px; width: 170px;}
    .overview .box-strong { margin: 0px 35px 0px 32px;}
    .overview strong.eth { font-size: 15px; line-height: 19px;width: 20px;}
    .overview span.one-number { font-size: 15px; line-height: 19px; }
    /* .overview h3.accounts {font-size: 23px;line-height: 28px;} */
    /* .overview .start-box { padding: 27px 17px;} */
    /* .overview h5 { font-size: 17px; line-height: 22px;} */
    /* .overview strong.five-busd { padding: 12px; margin-right: 8px; font-size: 15px; line-height: 19px;} */
    /* .overview span.three-pixp {font-size: 13px;  line-height: 17px;} */
}

@media (max-width:1580px) {
    .overview .wallet-heading{ margin-bottom: 28px;}
    .overview span.tusd { font-size: 16px;line-height: 20px;}
    .overview span.circle { height: 165px; width: 165px;}
    /* .overview .start-box{width:calc(50% - 5px);} */
}

@media (max-width:1399px){
    .overview .wallet-heading{  margin-bottom: 25px;}
    .overview span.circle {height: 140px;width: 140px;}
    .overview .box-strong { margin: 0px 27px 0px 25px;}
    .overview strong.eth {font-size: 14px;}
    .overview span.one-number { font-size: 14px ; line-height: 18px;}
    .overview li.list-overview.list-m{ margin-bottom: 12px;}
    /* .overview h3.accounts {font-size: 22px;line-height: 26px;} */
    /* .overview .start-box {padding: 24px 15px;} */
    /* .overview h5 {font-size: 16px;line-height: 20px;} */
    /* .overview strong.five-busd {font-size: 14px;line-height: 18px;} */
    .overview .account-main { margin-bottom: 220px;}
}

@media (max-width:1199px){
    .overview .wallet-heading{  margin-bottom: 22px;}
    .overview span.circle { height: 100px; width: 100px;}
    .overview strong.eth {font-size: 13px;}
    .overview .box-strong {margin: 0px 20px 0px 10px;}
    .overview span.one-number {font-size: 13px;line-height: 17px;}
    .overview li.list-overview.list-m { margin-bottom: 10px;}
    /* .overview span.tusd { font-size: 13px; line-height: 18px;} */
    /* .overview h3.accounts {font-size: 20px; line-height: 24px;} */
    .overview .account-main { margin-bottom: 180px;}
}

@media (max-width:991px){
    .wallet-overview.d-flex.justify-content-between.align-items-center {display: block !important;}
    .overview .wallet-heading{  margin-bottom: 20px;}
    .overview .heading-span { margin-bottom: 25px;}
    .overview .circle-span{justify-content: space-around;}
    /* .overview h3.accounts {font-size: 19px;line-height: 23px;} */
    .overview strong.eth {font-size: 12px;font-weight: 16px;}
    .overview span.one-number {font-size: 12px;line-height: 16px;}
    /* .overview .start-box{width:100%;margin: 0 0 5px;padding: 20px 15px;} */
    /* .overview h5 {font-size: 15px;line-height: 19px;margin-bottom: 15px;} */
    /* .overview strong.five-busd {font-size: 13px;line-height: 17px; } */
    /* .overview span.three-pixp {font-size: 12px;line-height: 16px;} */
    .overview .account-main {margin-bottom: 150px;}
}

@media (max-width:767px){
    .overview { position: relative;}
    .overview button.list.btn.btn-primary img { width: 20px;}
    .overview .wallet-heading{  margin-bottom: 15px;}
    .overview span.circle {height: 130px;width: 130px;}
    .overview .box-strong { margin: 0px 20px 0px 0;}
    .overview li.list-overview.list-m { margin-bottom: 6px;}
    /* .overview h3.accounts {font-size: 18px; line-height: 22px;} */
    /* .overview h5 {font-size: 14px;  line-height: 18px;  margin-bottom: 12px;} */
    /* .overview .start-box { padding: 15px;} */
    /* .overview strong.five-busd {font-size: 12px;line-height: 16px;} */
    .overview .account-main {margin-bottom: 120px;}
}

@media (max-width:575px){
    .overview .wallet-heading{  margin-bottom: 14px;}
    .overview span.circle { height: 120px; width: 120px; margin: 0 auto 20px;}
    .overview li.list-overview {justify-content: center;}
    /* .overview h3.text-white.accounts.d-flex{ justify-content: center;} */
    /* .overview h5 { text-align: center;} */
    /* .overview .overview-account {justify-content: center;} */
    .overview .account-main { margin-bottom: 90px;}
}

@media (max-width:480px){
    .overview .wallet-heading{  margin-bottom: 10px;}
    .overview span.tusd { font-size: 12px;line-height: 16px;}
    /* .overview button.transfer.btn { font-size: 14px;} */
}

@media (max-width:320px){
    .overview span.tusd {text-align: center;display: block;}
    .overview .overview-list { width: 201px;}
    .overview li.box-span {padding: 5px 7px; }
}
