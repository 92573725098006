.buy-crypto{ background-color: #02021A;height: 125px;margin-bottom: 125px;}
.buy-crypto .crypto-unorder-list {position: relative;}
.buy-crypto .crypto-unorder-list ul.img-span-unorder-list{position:absolute;background:#FACB4D;width:100%;right:0;bottom:0;height:100px;transform:rotate(-6deg); padding-right: 0;}
.buy-crypto .crypto-unorder-list ul.img-span-unorder-list::before{content:"";background-color: #041134;width:9999px;height:100px;position:absolute;transform:rotate(12deg);z-index: -1;bottom:0;}
.buy-crypto .crypto-unorder-list ul.img-span-unorder-list::after{content:"";background-color:#FACB4D;width:9999px;height:100px;position:absolute;z-index: -1;}
.buy-crypto .crypto-unorder-list li.img-span-gap { width: 530px;}
.buy-crypto .crypto-unorder-list li.img-span-gap img {width:46px; margin-right: 20px;}
.buy-crypto .crypto-unorder-list li.img-span-gap span{color: #02021A;font-size:30px;line-height: 35px;font-weight: 700;font-family:'Outfit';margin-bottom: 0;}

@media (max-width:1780px) {
    .buy-crypto .crypto-unorder-list li.img-span-gap span {font-size: 28px;line-height: 32px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap { width: 480px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap img { width: 40px; margin-right: 18px;}
    /* .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::before { bottom: 25px;} */
    .buy-crypto {height: 100px;margin-bottom: 100px;}
}

@media (max-width:1580px) {
    .buy-crypto .crypto-unorder-list li.img-span-gap span {font-size: 25px;line-height: 30px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap img { width: 35px; margin-right: 15px;}
    .buy-crypto {margin-bottom: 75px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::after {height: 90px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list {height: 90px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::before { height: 90px;}
}

@media (max-width:1399px) {
    .buy-crypto {margin-bottom: 50px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap span {font-size: 20px;line-height: 26px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap { width: 340px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap img {width: 30px;margin-right: 12px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::before { bottom: 0px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::after {height: 80px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list {height: 80px; bottom: 45px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::before { height: 80px;}
}

@media (max-width:1199px) {
    .buy-crypto {margin-bottom: 20px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap { width: 280px; }
    .buy-crypto .crypto-unorder-list li.img-span-gap span {font-size: 17px;line-height: 22px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap img {width: 25px;margin-right: 10px; }
    /* .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::before { bottom: 12px;} */
}


@media (max-width:991px) {
    .buy-crypto {margin-bottom: 0;height: 40px; }
    .buy-crypto .crypto-unorder-list li.img-span-gap span { font-size: 15px; line-height: 20px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap img { width: 20px; margin-right: 10px;}
    .buy-crypto .crypto-unorder-list li.img-span-gap { width: 240px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::after {height: 70px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list {height: 70px;bottom: 77px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::before { height: 70px; bottom: -3px;}
}

@media (max-width:767px) {
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::before {display: none; }
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list { position: relative; transform: rotate(0deg);height: 100%; padding: 25px; }
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list::after { display: none; }
    .buy-crypto .crypto-unorder-list li.img-span-gap {width: 100%;}
    .buy-crypto {margin-bottom: 100px;height: 0; }
}

@media (max-width:575px) {
    .buy-crypto .crypto-unorder-list li.img-span-gap span { font-size: 14px; line-height: 19px;}
    .buy-crypto .crypto-unorder-list ul.img-span-unorder-list {padding: 20px;}
}

@media (max-width:480px) {
    .buy-crypto .crypto-unorder-list li.img-span-gap span { font-size: 12px; line-height: 18px; }
    .buy-crypto { padding-top: 20px;}
}
