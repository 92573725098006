.trusted-millions{padding:220px 0 300px 0;margin:0 0 10px;background-image:url('../../assets/images/banner-bg2.png');background-repeat:no-repeat;background-position:top;background-size:cover;position:relative;}
.trusted-millions .main-trusted-millions{margin-bottom: 142px; padding: 30px 0 0 0;}
.trusted-millions .main-trusted-millions h2{font-size: 50px;line-height: 60px;font-weight:700;font-family:'Outfit';margin-bottom: 48px;}
.trusted-millions .main-trusted-millions p{font-size:24px;line-height:34px;font-weight:400;margin-bottom:50px;color:#BFBFD6;font-family:'Outfit';max-width: 705px;}
.trusted-millions .main-trusted-millions-img {width: 721px;margin: 0 auto;}
.trusted-millions .span-unorder-list li.banner-gap{margin-right: 16px;}
.trusted-millions .span-unorder-list {margin-bottom: 150px;position: relative;z-index: 1;}

@media (max-width:1780px) {
    .trusted-millions { padding: 200px 0 290px 0;}
    .trusted-millions .main-trusted-millions h2 { font-size: 40px; line-height: 50px; margin-bottom: 40px;}
    .trusted-millions .main-trusted-millions p {font-size: 22px;margin-bottom: 45px;}
    .trusted-millions .main-trusted-millions {margin-bottom: 125px;padding: 25px 0 0 0;}
    .trusted-millions .main-trusted-millions-img {width: 700px;}
}

@media (max-width:1580px) {
    .trusted-millions { padding: 200px 0 260px 0; }
    .trusted-millions .main-trusted-millions-img { width: 100%;}
    .trusted-millions .main-trusted-millions h2 {font-size: 38px;line-height: 48px;margin-bottom: 38px;}
    .trusted-millions .main-trusted-millions p {font-size: 21px;margin-bottom: 43px;}
    .trusted-millions .main-trusted-millions {margin-bottom: 110px;padding: 20px 0 0 0;}
}

@media (max-width:1399px) {
    .trusted-millions { padding: 200px 0 230px 0; }
    .trusted-millions .main-trusted-millions h2 { font-size: 34px; line-height: 44px; margin-bottom: 35px;}
    .trusted-millions .main-trusted-millions p {font-size: 19px;margin-bottom: 40px;}
    .trusted-millions .main-trusted-millions { margin-bottom: 100px; }
}

@media (max-width:1199px) {
    .trusted-millions {padding: 175px 0 200px 0;}
    .trusted-millions .main-trusted-millions {margin-bottom: 90px;}
    .trusted-millions .main-trusted-millions h2 {font-size: 28px;line-height: 38px;margin-bottom: 30px;}
    .trusted-millions .main-trusted-millions p { font-size: 16px; margin-bottom: 37px;} 
}

@media (max-width:991px) {
    .trusted-millions { padding: 140px 0 160px 0;}
    .trusted-millions .main-trusted-millions h2 {font-size: 21px;line-height: 28px;margin-bottom: 25px;}
    .trusted-millions .main-trusted-millions p {font-size: 15px;line-height: 22px;margin-bottom: 35px;}
    .trusted-millions .main-trusted-millions { padding: 10px 0 0 0;}
    .trusted-millions .main-trusted-millions { margin-bottom: 80px;}
}

@media (max-width:767px) {
    .trusted-millions {padding: 120px 0;}
    .trusted-millions .main-trusted-millions h2 { font-size: 20px; line-height: 25px; margin-bottom: 20px;}
    .trusted-millions .main-trusted-millions p {font-size: 14px;line-height: 20px;margin-bottom: 30px;}
    .trusted-millions .main-trusted-millions {margin-bottom: 70px;}
    .trusted-millions .span-unorder-list span {font-size: 14px;line-height: 19px;}
    .trusted-millions .span-unorder-list li.banner-gap { margin-right: 10px; }
    .trusted-millions .span-unorder-list {margin-bottom: 70px;}
}

@media (max-width:575px) {
    .trusted-millions {padding: 100px 0;}
    .trusted-millions .main-trusted-millions { margin-bottom: 50px;}
}

@media (max-width:480px) {
    .trusted-millions .main-trusted-millions h2 {font-size: 18px;line-height: 24px;}
    .trusted-millions .main-trusted-millions p {font-size: 12px;line-height: 18px;margin-bottom: 25px; }
    .trusted-millions .main-trusted-millions { margin-bottom: 40px;}
    .trusted-millions .span-unorder-list { margin-bottom: 50px; }
}

@media (max-width:320px) {
    .trusted-millions {padding: 80px 0;}
    .trusted-millions .main-trusted-millions h2 { font-size: 16px; line-height: 22px;}
}
